import Box from "@mui/material/Box";
import componentColor from "themes/componentColor";

export const Footer = () => {
  let currentYear = new Date().getFullYear();
  const styles = {
    color: componentColor.appBarColor,
    fontSize: "10px",
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "10px",
  };

  return <Box style={styles}>Copyright © {currentYear} WHOSEAPPS DOT COM SDN BHD</Box>;
};
