import { memo } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const Progress = memo(() => {
  return (
    <Box sx={{ width: "100vw", height: "100vh", position: "absolute", backgroundColor: "#00000095", zIndex: 2000 }}>
      <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%", color: "red" }} />
    </Box>
  );
});
