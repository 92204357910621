export const zsHome = (set) => ({
  activeStep: 0,
  setActiveStep: (v) => set(() => ({ activeStep: v })),

  selectedMenuTab: 0,
  setSelectedMenuTab: (v) => set(() => ({ selectedMenuTab: v })),

  searchCriteria: { searchText: "", startDate: null, endDate: null, adult: 1, child: 0, bedroom: 1, extra_bed: 0, bathroom: 1 },
  updateSearchCriteria: (item) => set((state) => ({ searchCriteria: { ...state.searchCriteria, ...item } })),

  searchAlert: { alertOpen: false, alertText: "" },
  updateSearchAlert: (item) => set((state) => ({ searchAlert: { ...state.searchAlert, ...item } })),

  pageLoaded: { mainTab0: false, mainTab1: false, mainTab2: false, mainTab3: false, mainTab4: false },
  updatePageLoaded: (item) => set((state) => ({ pageLoaded: { ...state.pageLoaded, ...item } })),
});
