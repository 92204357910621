import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, pink } from "@mui/material/colors";
// import { deDE } from "@mui/x-date-pickers/locales";

const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        xs1: 350,
        xs2: 400,
        xs3: 450,
        xs4: 500,
        xs5: 550,
        sm: 600,
        sm1: 700,
        sm2: 800,
        md: 900,
        md1: 1000,
        md2: 1100,
        lg: 1200,
        lg1: 1300,
        lg2: 1400,
        xl: 1536,
      },
    },
    typography: {
      allVariants: {
        fontFamily: "Raleway",
        textTransform: "none",
        // fontSize: 15,
      },
    },
    palette: {
      primary: {
        main: grey[700],
      },
      secondary: {
        main: pink[500],
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          sx: {
            px: 1,
          },
          variant: "subtitle2",
          textTransform: "capitalize",
        },
      },
      MuiStack: {
        defaultProps: {
          sx: {
            px: 2,
            py: 1,
          },
          spacing: 2,
          direction: "row",
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiLink: {
        defaultProps: {
          sx: {
            color: (theme) => theme.palette.primary.main,
          },
          underline: "none",
        },
      },
      MuiButton: {
        defaultProps: {
          size: "small",
          p: 0,
          disableRipple: true,
        },
        variant: "text",
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  }
  // deDE // use 'de' locale for UI texts (start, next month, ...)
);

const AppThemeProvider = (prop) => {
  return <ThemeProvider theme={theme}> {prop.children} </ThemeProvider>;
};

export default AppThemeProvider;
