import { memo } from "react";
import { useZustandStore } from "settings/useZustandStore";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Logo } from "./components";

export const Header = memo(({ user = {}, setLeftDrawerOpen, setRightDrawerOpen, signOut = {} }) => {
  const breakpointInt = useZustandStore((state) => state.breakpointInt);
  const theme = useTheme();

  return (
    <AppBar component="nav" elevation={0} sx={{ color: "black", backgroundColor: "#333333" }}>
      <Toolbar>
        {/* {breakpointInt < theme.breakpoints.values.sm && (
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => setLeftDrawerOpen((prevState) => !prevState)} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>
        )} */}
        <Logo />
      </Toolbar>
    </AppBar>
  );
});
