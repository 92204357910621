export const zsHostSignup = (set) => ({
  signupStep: 0,
  setSignupStepBack: () => set((state) => ({ signupStep: state.signupStep >= 1 ? state.signupStep - 1 : 0 })),
  setSigpupStepNext: () => set((state) => ({ signupStep: state.signupStep < 10 ? state.signupStep + 1 : 10 })),

  propertyList: [],
  setPropertyList: (properties) => set({ propertyList: properties }),

  propertyListChange: false,
  setPropertyListChange: () => set((state) => ({ propertyListChange: !state.propertyListChange })),

  currentProperty: {},
  setCurrentProperty: (property) => set({ currentProperty: property }),
  updateCurrentProperty: (item) => set((state) => ({ currentProperty: { ...state.currentProperty, ...item } })),

  pendingPropertyUpdate: {},
  setPendingPropertyUpdate: (updates) => set({ pendingPropertyUpdate: updates }),
  updatePendingPropertyUpdate: (item) => set((state) => ({ pendingPropertyUpdate: { ...state.pendingPropertyUpdate, ...item } })),

  isLoading: false,
  setIsLoading: (v) => set(() => ({ isLoading: v })),

  loadingProgress: 0,
  setLoadingProgress: (progress) => set(() => ({ loadingProgress: progress })),

  signupBackBtnEnable: true,
  setSignupBackBtnEnable: (v) => set(() => ({ signupBackBtnEnable: v })),
  signupNextBtnEnable: true,
  setSignupNextBtnEnable: (v) => set(() => ({ signupNextBtnEnable: v })),
  signupP1Selected: null,
  setSignupP1Selected: (id) => set(() => ({ signupP1Selected: id })),
  signupP2Selected: null,
  setSignupP2Selected: (id) => set(() => ({ signupP2Selected: id })),
  signupP3LngLat: { lng: null, lat: null },
  setSignupP3LngLat: (lnglat) => set(() => ({ signupP3LngLat: lnglat })),

  allPagePass: false,
  setAllPagePass: (v) => set(() => ({ allPagePass: v })),

  accordion1ImgObj: [],
  addAccordion1ImgObj: (imageKey) => set((state) => ({ accordion1ImgObj: [...state.accordion1ImgObj, imageKey] })),
  removeAccordion1ImgObj: (imageKey) =>
    set((state) => {
      const obj = state.accordion1ImgObj.filter((imageKey1) => {
        return !imageKey1.key.includes(imageKey);
      });
      console.log("obj", obj);
      return { accordion1ImgObj: obj };
    }),
});
