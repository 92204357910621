import { useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import { Header, Body, Progress, LeftDrawer, RightDrawer } from "pages/Home/indexComponents";
import componentColor from "themes/componentColor";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);

  const styles = {
    marginTop: "85px",
    width: "100px",
    maxWidth: "100px",
    overflowX: "hidden",
    backgroundColor: componentColor.bodyBackground,
    marginBottom: "10px",
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: componentColor.bodyBackground }}>
      <CssBaseline />
      {isLoading && <Progress />}
      <Header setLeftDrawerOpen={setLeftDrawerOpen} setRightDrawerOpen={setRightDrawerOpen} />
      <LeftDrawer drawerWidth={240} leftDrawerOpen={leftDrawerOpen} setLeftDrawerOpen={setLeftDrawerOpen} />
      <RightDrawer drawerWidth={240} rightDrawerOpen={rightDrawerOpen} setRightDrawerOpen={setRightDrawerOpen} />
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Link href="https://s.lazada.com.my/s.iUkPd">
          <Box
            component="img"
            style={{ ...styles, marginRight: "-25px" }}
            sx={{
              display: { xs: "none", md: "flex" },
            }}
            src={require("pages/images/banner1.png")}
          />
        </Link>
        <Body />

        <Link href="https://s.lazada.com.my/s.iUkPd">
          <Box
            component="img"
            style={{ ...styles, marginLeft: "-25px" }}
            sx={{
              display: { xs: "none", md: "flex" },
            }}
            src={require("pages/images/banner1.png")}
          />
        </Link>
      </Box>
    </Box>
  );
};
export default Home;
