import { useZustandStore } from "settings/useZustandStore";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import componentColor from "themes/componentColor";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const Logo = () => {
  const breakpointStr = useZustandStore((state) => state.breakpointStr);
  const contactNumber = "+6011 6060 6885";
  const styles = {
    height: "40px",
    maxHeight: "40px",
    marginLeft: "0px",
    paddingLeft: "0px",
  };
  return (
    <Box style={{ width: "100%", display: "flex", flexDirection: "row", backgroundColor: componentColor.appBarBackground }}>
      <IconButton color="inherit" style={{ paddingLeft: "0px" }}>
        <Box component="img" src={require("pages/images/website_logo.png")} style={styles} />
      </IconButton>
      <Box style={{ flexGrow: 1, backgroundColor: "#333333" }} />
      <IconButton color="inherit">
        <WhatsAppIcon style={{ fontSize: "22px", color: "white" }} />
        <Box style={{ marginLeft: "5px", fontSize: "18px", color: "white" }}>+6011 6060 6885</Box>
      </IconButton>
    </Box>
  );
};
