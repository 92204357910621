import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import Home from "./pages/Home/Home";
import RedirectToHomePage from "./pages/redirect/RedirectToHomePage";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Home />} />
        <Route path="/error">
          <Route index element={<RedirectToHomePage />} />
          <Route path="*" element={<RedirectToHomePage />} />
        </Route>
        <Route path="/*" element={<RedirectToHomePage />} />
      </>
    )
  );

  return (
    <>
      <CssBaseline />
      <main>
        <RouterProvider router={router} />
      </main>
    </>
  );
}

export default App;
