import { memo } from "react";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Footer } from "./Footer";
import componentColor from "themes/componentColor";

export const Body = memo(() => {
  const styles = {
    width: "100%",
    overflowX: "hidden",
    backgroundColor: componentColor.bodyBackground,
    marginTop: "10px",
    marginBottom: "10px",
  };

  return (
    <Container>
      <Toolbar />
      <Box component="main" sx={styles}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Paper component="img" src={require("pages/images/marketplace_airbnb.png")} elevation={3} style={styles} />
          <Paper component="img" src={require("pages/images/system_hotel_cms.png")} elevation={3} style={styles} />
          <Paper component="img" src={require("pages/images/system_ecommerce_cms.png")} elevation={3} style={styles} />
          <Paper component="img" src={require("pages/images/business_webdesign.png")} elevation={3} style={styles} />
          <Paper component="img" src={require("pages/images/system_gym_cms.png")} elevation={3} style={styles} />
        </Box>
      </Box>
      <Footer />
    </Container>
  );
});
