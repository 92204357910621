import { memo } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export const RightDrawer = memo(({ drawerWidth = 240, container, rightDrawerOpen = false, setRightDrawerOpen }) => {
  const navItems = ["Home", "About", "Contact"];

  const rightDrawerContent = (
    <>
      <Toolbar />
      <Box onClick={() => setRightDrawerOpen((prevState) => !prevState)} sx={{ textAlign: "center", backgroundColor: "red" }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          AnyRooom.com
        </Typography>
        <Divider />
        <List>
          {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Toolbar />
    </>
  );

  return (
    <nav>
      <Drawer
        anchor="right"
        container={container}
        variant="temporary"
        open={rightDrawerOpen}
        onClose={setRightDrawerOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {rightDrawerContent}
      </Drawer>
    </nav>
  );
});
